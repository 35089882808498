<template>
  <div class="control-container" style="font-size:14px">
    <a-range-picker
      style="width: 160px; margin-right: 5px"
      format="MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="modelDate"
      :allowClear="false"
      :disabledDate="disabledDate"
      size="small"
      ref="datepicker"
      :disabled="+isMock === 1"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
    />
    <!-- <a-select
      :filter-option="filterOption"
      showSearch
      placeholder="应用筛选"
      v-model="appIds"
      style="width: 220px;margin:0 10px"
      mode="multiple"
      :maxTagCount="1">
      <a-select-option v-for="item in appList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
    </a-select> -->
    <m-select-more
      style="width: 160px; margin-right: 5px"
      size="small"
      :showLabel="false"
      v-model="appIds"
      :allData="appList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="应用"
      @change="changeAppIds"
      :disabled="+isMock === 1"
    />
    <div class="indicator">
      <a-select :value="i1" size="small" style="width: 110px; margin-right: 10px" @change="selectIndicator($event, 1)">
        <a-select-option v-for="(item, i) in targetList" :key="i" :disabled="i2 === item.value" :value="item.value">
          {{ item.label }}
        </a-select-option>
      </a-select>
      <a-select :value="i2" size="small" style="width: 110px" @change="selectIndicator($event, 2)">
        <a-select-option v-for="(item, i) in targetList" :key="i" :disabled="i1 === item.value" :value="item.value">
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
import { getDuration } from '@/utils/duration'
import { mapState } from 'vuex'
export default {
  mixins: [mixDate, mixGetList],
  model: {
    prop: 'time',
    event: 'changeTime'
  },
  data () {
    return {
      modelDate: [],
      targetList: [
        { label: 'DAU', value: 'dau', color: '#ffab31' },
        { label: 'DEU', value: 'deu', color: 'rgb(76, 180, 231)' },
        { label: '收益', value: 'unitRevenue', color: '#de7af9' },
        { label: 'eCPM', value: 'ecpm', color: 'rgb(0, 118, 143)' },
        { label: 'ARPDAU', value: 'arpu', color: '#ff746c' },
        { label: 'ARPDEU', value: 'arpDeu', color: '#ff4400' },
        { label: '预估收益', value: 'estimatedRevenue', color: '#39da61' },
        { label: '预估eCPM', value: 'estimatedRevenueEcpm', color: '#ff6688' },
        { label: '流量请求', value: 'request', color: 'rgb(145, 78, 0)' },
        { label: '展示', value: 'impression', color: '#0055ff' },
        { label: '展示 / DAU', value: 'aipau', color: '#a8a8ff' },
        { label: '渗透率', value: 'permeability', color: 'rgb(255, 192, 159)' },
        { label: '展示 / DEU', value: 'aipu', color: 'rgb(59, 203, 213)' },
        { label: 'SDK版本', value: 'sdkVersion', color: 'rgb(59, 203, 213)' }
      ],
      appIds: []
    }
  },
  props: {
    time: {
      type: Array,
      default: () => []
    },
    indicator1: {
      type: String,
      default: ''
    },
    indicator2: {
      type: String,
      default: ''
    },
    appIdList: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    // appIds: {
    //   get () {
    //     return this.appIdList
    //   },
    //   set (val) {
    //     this.$emit('changeAppList', val)
    //   }
    // },
    ...mapState({
      isMock: (state) => state.user.isMock
    }),
    i1 () {
      return this.indicator1
    },
    i2 () {
      return this.indicator2
    }
  },
  watch: {
    time: {
      handler (val) {
        this.modelDate = [...val]
      }
    }
  },
  created () {
    this.getAppList()
  },
  mounted () {
  },

  methods: {
    changeAppIds (ids) {
      this.$emit('changeAppList', ids)
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    getColor (val) {
      return this.targetList.filter((item) => item.value === val)[0].color
    },
    ChangeDate (date) {
      const start = date[0].format('YYYY-MM-DD')
      const end = date[1].format('YYYY-MM-DD')
      this.$emit('changeTime', date)
      this.$emit('changeDate', [start, end])
    },
    selectIndicator (val, index) {
      this.$emit('changeIndicator', [val, index])
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.control-container {
  padding: 10px;
  height: 50px;
  display: flex;
  // justify-content: flex-end;
  vertical-align: top;
  .reportdatas-container .ant-calendar-picker-input.ant-input {
    padding: 4px 3px;
    .ant-calendar-range-picker-input {
      width: 40%;
    }
  }
}
::v-deep.control-container .ant-calendar-picker.ant-calendar-picker-small {
  .ant-calendar-picker-input.ant-input:focus {
    box-shadow: none !important;
  }
}
</style>
