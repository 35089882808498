export default {
  tooltip: {
    trigger: 'axis',
    transitionDuration: 0,
    extraCssText: 'width: 180px',
    // confine: true,
    appendToBody: true,
    formatter: (params) => {
      let str = ''
      params.forEach((item, index) => {
        const temp = {}
        if (
          item.seriesName === '收益' ||
          item.seriesName === 'eCPM' ||
          item.seriesName === '预估收益' ||
          item.seriesName === 'ARPDAU'
        ) {
          temp.value = '￥' + item.value
        } else if (item.seriesName === '渗透率') {
          temp.value = item.value + '%'
        } else {
          temp.value = item.value
        }
        str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
      })
      return params[0].axisValue + '<br>' + str
    }
  },
  legend: {
    data: [],
    top: '0%'
    // itemGap: 14,
    // itemWidth: 14,
    // itemHeight: 20
  },
  grid: {
    top: '10%',
    left: '2%',
    right: '2%',
    bottom: '0%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLine: {
      lineStyle: {
        color: '#8d8d8d',
        width: 0.5
      }
    }
  },
  yAxis: [
    // 0

    // 1
    {
      type: 'value',
      position: 'left',
      axisLabel: {
        formatter: '{value}',
        show: true,
        color: '#8d8d8d'
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      }
    },
    {
      type: 'value',
      axisLabel: {
        formatter: '{value}',
        show: true,
        color: '#8d8d8d'
      },
      position: 'right',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#e5e5e5'
        }
      }
    }
  ],
  series: []
}
