<template>
  <div class="data-indicator-container">
    <div class="data-indicator-header">
      <HomeTitle title="数据趋势" borderColor="#91CC75" />
      <Control
        v-model="rangePickerDate"
        @changeDate="changeDate"
        :indicator1="indicator1"
        :indicator2="indicator2"
        :appIdList="query.appIdList"
        @changeIndicator="changeIndicator"
        @changeAppList="changeAppList"
      />
    </div>
    <a-spin :spinning="spinning">
      <div ref="chart" class="chart"></div>
    </a-spin>
  </div>
</template>

<script>
import HomeTitle from '../components/homeTitle'
import mixDate from '@/mixins/initDate'
import Control from './control'
import { dashboardChart } from '@/api/dashboard'
import options from './option'
import { numFormat } from '@/utils/dealNumber'
import { mapState } from 'vuex'
import { mockData } from './mockData'
import * as echarts from 'echarts'
export default {
  components: { HomeTitle, Control },
  mixins: [mixDate],
  data () {
    return {
      spinning: false,
      query: {
        dateEnd: '',
        dateStart: '',
        appIdList: []
      },
      queryTemp: {},
      indicator1: 'dau',
      indicator2: 'deu',
      datas: {
        lable: [],
        list: []
      },
      options: {},
      myEcharts: null,
      colorList: ['#ffab31', '#ff4400', '#39da61', '#0055ff', '#a8a8ff'],
      seriesTemplate: {
        name: '',
        type: 'line',
        // stack: 'Total',
        areaStyle: {
          color: 'rgba(50, 102, 255, 0.1)'
        },
        smooth: true,
        yAxisIndex: 0,
        data: [],
        symbol: 'circle', // 设定为实心点
        symbolSize: 5, // 设定实心点的大小
        itemStyle: {
            color: 'rgba(50, 102, 255, 1)',
            lineStyle: {
              color: 'rgba(50, 102, 255, 1)',
              width: 3
            }
        }
      }
    }
  },
  watch: {
    isMock: {
      handler () {
        this.getData()
      }
    }
  },
  computed: {
    ...mapState({
      isMock: (state) => state.user.isMock
    })
  },
  mounted () {
    this.options = JSON.parse(JSON.stringify(options))
    this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
    this.initChart()
    window.addEventListener('resize', this.getSize)
    const { endDate, startDate, end, start } = this.initDate(6, 0)
    this.rangePickerDate = [start, end]
    this.query.dateEnd = endDate
    this.query.dateStart = startDate
    this.queryTemp = JSON.parse(JSON.stringify(this.query))
    this.getData()
  },
  destroyed () {
    this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        setTimeout(() => {
          this.myEcharts.resize()
        }, 100)
      }
    },
    initChart () {
      this.myEcharts.setOption(this.options, true, { render: 'svg' })
    },
    changeDate (datas) {
      this.query.dateEnd = datas[1]
      this.query.dateStart = datas[0]
      this.getData()
    },
    async getData () {
      this.spinning = true
      let resp
      if (+this.isMock === 0) {
        resp = await dashboardChart(this.query)
      } else {
        resp = mockData
        const res = await dashboardChart(this.queryTemp)
        resp.data.lable = res.data.lable
      }
      this.datas = resp.data || {
        lable: [],
        list: []
      }
      this.setOptions()
      this.spinning = false
    },
    changeAppList (ids) {
      this.query.appIdList = ids
      this.getData()
    },
    changeIndicator (event) {
      if (event[1] === 1) {
        this.indicator1 = event[0]
      } else {
        this.indicator2 = event[0]
      }
      this.getData()
    },
    /**
     * item 每个sdk数据
     * index 该条数据的索引
     * datas 所有sdk数据的嵌套数组
     * y 所对应的y轴
     */
    async setSDKSeries (item, index, datas, y) {
      const series = JSON.parse(JSON.stringify(this.seriesTemplate))
      series.data = datas[index] || []
      series.name = item || ''
      series.yAxisIndex = y
      series.itemStyle.color = this.colorList[index]
      series.itemStyle.lineStyle.color = this.colorList[index]
      delete series.areaStyle
      this.options.legend.data.push(item)
      this.options.series.push(series)
    },
    /**
     * data 每个指标对应的数据
     * i 指标索引
     */
    async setOtherSeries (data, index) {
      const series = JSON.parse(JSON.stringify(this.seriesTemplate))
      const name = data.name
      const datas = data.data || []
      series.data = datas
      series.name = name || ''
      series.yAxisIndex = index
      series.itemStyle.color = index === 0 ? 'rgba(50, 102, 255, 1)' : 'rgba(38, 228, 153, 1)'
      series.itemStyle.lineStyle.color = index === 0 ? 'rgba(50, 102, 255, 1)' : 'rgba(38, 228, 153, 1)'
      series.areaStyle.color =
        index === 0
          ? {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(50, 102, 255, 0.5)' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(50, 102, 255, 0)' // 100% 处的颜色
                }
              ],
              globalCoord: false // 缺省为 false
            }
          : {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(38, 228, 153, 0.5)' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(38, 228, 153, 0)' // 100% 处的颜色
                }
              ],
              globalCoord: false // 缺省为 false
            }
      this.options.legend.data.push(name || '')
      this.options.series.push(series)
    },
    /**
     * data  每个指标对应的数据
     * i 指标索引
     */
    async setIndicatorLine (data, i) {
      if (data.field === 'sdkVersion') {
        const versionList = Object.keys(data.data)
        const datas = Object.values(data.data)
        versionList.forEach(async (item, index) => {
          await this.setSDKSeries(item, index, datas, i)
        })
      } else {
        await this.setOtherSeries(data, i)
      }
    },
    async setOptions () {
      this.options.xAxis.data = this.datas.lable
      this.options.series = []
      this.options.legend.data = []
      const list = this.datas.list
      const data1 = list.filter((item) => item.field === this.indicator1)[0] || []
      // 第一项
      await this.setIndicatorLine(data1, 0)

      const data2 = list.filter((item) => item.field === this.indicator2)[0] || []
      // 第二项
      await this.setIndicatorLine(data2, 1)

      this.options.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          const temp = {}
          if (
            item.seriesName === '收益' ||
            item.seriesName === 'eCPM' ||
            item.seriesName === '预估收益' ||
            item.seriesName === 'ARPDAU'
          ) {
            temp.value = '￥' + numFormat(item.value, 3, '')
          } else if (item.seriesName === '渗透率' || item.seriesName.includes('.')) {
            temp.value = item.value + '%'
          } else {
            temp.value = numFormat(item.value, 3, '')
          }
          str +=
            `<div style='width:100%;display:flex;justify-content:space-between'><span>` +
            item.marker +
            item.seriesName +
            ': </span>' +
            temp.value +
            '</div>'
        })
        return params[0].axisValue + '<br>' + str
      }
      this.initChart()
    }
  }
}
</script>

<style lang="less">
.data-indicator-container {
  background: #fff;
  border-radius: 0.35em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // .data-indicator-header{
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-start;
  // }
  .ant-spin-nested-loading {
    height: 0;
    flex-grow: 1; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    -webkit-box-flex-grow: 1; /* OLD - iOS 6-, Safari 3.1-6 */
    .ant-spin-container {
      position: relative;
      height: 90%;
      .chart {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0.714em;
      }
    }
  }
}
</style>
