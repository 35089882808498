export const mockData = {
  code: 200,
  msg: 'success',
  data: {
    lable: ['20230526', '20230527', '20230528', '20230529', '20230530', '20230531', '20230601'],
    list: [
      {
        check: false,
        data: [4562931, 4520907, 4477118, 4404767, 4400945, 4411201, 2559185],
        field: 'impression',
        name: '展示'
      },
      {
        check: false,
        data: [1502499, 1360563, 1305722, 1460225, 1455438, 1432676, 936647],
        field: 'dau',
        name: 'DAU'
      },
      {
        check: false,
        data: [78996.73, 76089.65, 75936.28, 77857.32, 80310.72, 107984.82, 66162.06],
        field: 'estimatedRevenue',
        name: '预估收益'
      },
      {
        check: false,
        data: [17.31, 16.83, 16.96, 17.68, 18.25, 24.48, 25.85],
        field: 'estimatedRevenueEcpm',
        name: '预估eCPM'
      },
      {
        check: false,
        data: [3.04, 3.32, 3.43, 3.02, 3.02, 3.08, 2.73],
        field: 'aipau',
        name: '展示/DAU'
      },
      {
        check: false,
        data: [78112.62, 75485.57, 75243.07, 77260.38, 79414.8, 102208.83, 0],
        field: 'unitRevenue',
        name: '收益'
      },
      {
        check: false,
        data: [17.67, 17.16, 17.26, 18.05, 18.59, 24.7, 0],
        field: 'ecpm',
        name: 'eCPM'
      },
      {
        check: false,
        data: [763369, 769986, 765279, 756478, 750237, 751949, 477416],
        field: 'deu',
        name: 'DEU'
      },
      {
        check: false,
        data: [0.51, 0.57, 0.59, 0.52, 0.52, 0.52, 0.51],
        field: 'permeability',
        name: '渗透率'
      },
      {
        check: false,
        data: [6516630, 6971481, 6050450, 5925447, 5874126, 5928621, 3423270],
        field: 'request',
        name: '流量请求'
      },
      {
        check: false,
        data: [0.052, 0.0555, 0.0576, 0.0529, 0.0546, 0.0713, 0],
        field: 'arpu',
        name: 'ARPDAU'
      },
      {
        check: false,
        data: [5.98, 5.87, 5.85, 5.82, 5.87, 5.87, 5.36],
        field: 'aipu',
        name: '展示/DEU'
      },
      {
        check: false,
        data: [0.1, 0.1, 0.1, 0.1, 0.11, 0.14, 0],
        field: 'arpDeu',
        name: 'ARPDEU'
      },
      {
        check: false,
        data: {
          '2.6.5': [38.4, 42.23, 43.8, 39.2, 39.23, 40, 39.75],
          '2.5.7': [33.57, 26.37, 25.46, 33.7, 33.95, 33.08, 34.7],
          '2.6.6': [10.65, 12.2, 12.09, 10.8, 10.77, 10.92, 11.85],
          '2.5.9': [6.46, 6.08, 6, 5.7, 5.5, 5.54, 5.16],
          '2.6.7': [5.6, 7.54, 7.22, 5.39, 5.28, 5.26, 4.08]
        },
        field: 'sdkVersion',
        name: 'SDK版本'
      }
    ]
  }
}
